import { exchangeAndRaOpenStatusEnum } from '@/constants/vip'
import { useUserStore } from '@/store/user'

/**
 * 交易所和娱乐区开启状态
 * @returns {Object} hasExchange 是否开启交易所 hasRa 是否开启娱乐区
 */
export const useAppContentType = () => {
  const userStore = useUserStore()
  const { exchange, ra } = userStore.userInfo || {}
  /** 是否开启交易所 */
  const hasExchange = exchange === exchangeAndRaOpenStatusEnum.open
  /** 是否开启娱乐区 */
  const hasRa = ra === exchangeAndRaOpenStatusEnum.open

  return { hasExchange, hasRa }
}
